module.exports = [{
      plugin: require('../plugins/gatsby-plugin-conecad/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/git/workspace/Bogie_error418_teapot_master/src/intl","languages":["en","fr"],"defaultLanguage":"en","linkComponent":"/home/git/workspace/Bogie_error418_teapot_master/plugins/gatsby-plugin-conecad/Link"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N2GTK5","dataLayerName":"dataLayer","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
