/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router'
import { Link as GatsbyLink, navigate as gatsbyNavigate } from 'gatsby';
import { SprayCanContext } from '@cof/graffiti-alley-spray-cans/context/app-context';

import { LOCALSTORAGE_LANG_KEY, NOT_FOUND_PATHS } from './constants';
const is = {
  relativeURL: url => /^\/(?!\/)/.test(url),
  capitalOneURL: url =>
    /^(https?:\/\/)?((www|www-qa-ca|www-dev-ca|fr|applynow|findyourcard|quickcheck-ui-canada-qa.aws-cof-canada-card-qa|applynow-qa).)?(capitalone.ca|.clouddqt.capitalone.com|capitalonegslbex.com|clouddqtext.capitalone.com)/.test(url),
  comparePageUrl: url => /\/credit-cards\/compare/.test(url),
  filterParam: param => param === 'filter',
};

const deriveQueryString = (location, parser) => {
  let qsParams = new URLSearchParams(location.search);
  if (location.pathname.indexOf("/quickcheck") < 0 && qsParams.has("qkref")) {
    // Only /quickcheck path can have qkref within WWW
    qsParams.delete("qkref")
  }
  qsParams.forEach((value, key) => {
    // Note: If same param exist in qs and href, the href's value is chosen to preserve URL
    if (!parser.searchParams.has(key)) parser.searchParams.append(key, value);
    // Note: On the compare card page, href's filter param value is updated when filter is changed when switching tabs
    else if (is.filterParam(key) && is.comparePageUrl(location.pathname)) parser.searchParams.set(key, value);
  });
  qsParams = parser.searchParams.toString();
  qsParams = qsParams ? `?${qsParams}` : '';
  return qsParams;
};

const DUMMY_BASE_FOR_URL_PARSER = 'https://localhost';
const VOID_URL = "javascript:void(0)"; // eslint-disable-line

const setLanguageInLocalStorage = (lang) => {
  try {
    localStorage.setItem(LOCALSTORAGE_LANG_KEY, lang);
  } catch {
    console.error('Local Storage is Disabled.')
  }
}

/**
 * This component is a wrapper around the Gatsby <Link> component adding some extra functionality.
 * (check properties docs for more info)
 */
const Link = ({ to, language, children, onClick, native, ...props }) => {
  const { localeData } = useContext(SprayCanContext);
  const location = useLocation();

  // The combination of isClient state and useEffect hook is necessary to fix initial render and rehydration issues.
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);

  let finalURL = to;
  const urlParser = new URL(to, DUMMY_BASE_FOR_URL_PARSER);
  const currentQueryString = isClient ? deriveQueryString(location, urlParser) : '';
  const languageQueryPath = language || localeData.language;
  const isLanguageLink = (localeData?.routed || language) && languageQueryPath !== localeData?.defaultLanguage;

  if (is.relativeURL(to)) {
    let finalPath = urlParser.pathname;
    if (isLanguageLink) finalPath = `${finalPath}${finalPath.slice(-1) !== '/' ? '/' : ''}${languageQueryPath}/`;
    finalURL = `${finalPath}${currentQueryString}${urlParser.hash}`;
  } else if (is.capitalOneURL(to)) {
    finalURL = `${urlParser.origin}${urlParser.pathname}${currentQueryString}${urlParser.hash}`;
  }

  const handleClick = e => {
    if (language) setLanguageInLocalStorage(language)
    if (onClick) onClick(e);
  };

  const isBypassBlock = props.className && props.className.includes('bypass-block');
  // Please refer to the original <Link> component on Spray Cans for any updates:
  // https://github.cloud.capitalone.com/graffiti-alley/spray-cans/blob/master/src/atoms/Link/Link.js
  if (!to || native.toString() === 'true' || props.target === '_blank' || isBypassBlock) {
    return to && to !== VOID_URL ? (
      <a href={finalURL} onClick={handleClick} {...props} data-testid="gatsby-a">
        {children}
      </a>
    ) : (
      <button type="button" onClick={handleClick} {...props} data-testid="gatsby-button">
        {children}
      </button>
    );
  }
  return (
    <GatsbyLink to={finalURL} onClick={handleClick} {...props} data-testid="gatsby-link">
      {children}
    </GatsbyLink>
  );
};

Link.propTypes = {
  /** link content */
  children: PropTypes.node.isRequired,
  /** url destination of the link */
  to: PropTypes.string,
  /** use this option to set a specific language to this link */
  language: PropTypes.string,
  /** allows custom functionality to be implemented as the user clicks the link */
  onClick: PropTypes.func,
  /** allows anchor element to be used rather then GatsbyLink */
  native: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Link.defaultProps = {
  to: null,
  native: false,
};

export default Link;

/**
 * This method wraps Gatsby navigation to deal with language specific features
 * before navigating
 */
export const navigate = (to, options) => {
  if (typeof window === 'undefined') return;

  const {
    defaultLanguage,
    languages,
    language,
    routed = false,
  } = Object.assign({}, window.___gatsbyIntl, options); // eslint-disable-line no-underscore-dangle

  let link = routed ? `${to}${language === defaultLanguage ? '' : `${language}/`}` : to;

  if (NOT_FOUND_PATHS(languages, defaultLanguage).includes(to) && routed) link = `/${language}${to}`;

  gatsbyNavigate(link, options);
};

/**
 * Handles language change, based on the current locale configuration
 */
export const changeLocale = (language, to) => {
  // console.log(language,to)
  if (typeof window === 'undefined') return;

  const {
    defaultLanguage,
    routed = false,
    allSitePage = [],
  } = window.___gatsbyIntl || {}; // eslint-disable-line no-underscore-dangle

  const removeLocaleInCurrentPath = pathname => {
    if (!routed) return pathname;
    const parts = pathname.split('/');
    if (parts[parts.length - 1] === '') parts.splice(parts.length - 2, 1);
    else parts.pop();
    return parts.join('/');
  };

  const pathname = to || removeLocaleInCurrentPath(window.location.pathname);
  const languageQueryPath = language === defaultLanguage ? '' : `${language}/`;
  const finalURL = `${pathname}${languageQueryPath}${window.location.search}`;
  setLanguageInLocalStorage(language)

  if (allSitePage.includes(pathname)) gatsbyNavigate(finalURL);
  else gatsbyNavigate(`/${languageQueryPath}`);
};
