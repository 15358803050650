export const PROD_HOSTNAMES = ['capitalone.ca', 'www.capitalone.ca'];
export const PROD_BETA_HOSTNAMES = ['wwwbeta.capitalone.ca'];
export const PROD_API_URL = 'https://api.capitalone.com';
export const NONPROD_API_URL = 'https://api-it.capitalone.com';
export const DEVEX_QA_BASE_URL = `https://api-inside-it.cloud.capitalone.com`;
export const MIMEO_MOCK_BASE_URL = `https://mimeo.clouddqt.capitalone.com:443/err418`;
export const CARDS_PRODUCT_ENDPOINT = `/credit-cards/canada/products/`;
export const QK_URL = 'https://findyourcard.capitalone.ca/';
export const DAPP_URL = 'https://applynow.capitalone.ca/';

export const ALL_BRANDS = ['gm', 'gsm', 'nfsrm', 'atp', 'acp'];
export const PRODUCT_DETAILS_BRANDS = ['gsm', 'atp', 'acp'];
export const BUILD_BRANDS = ['gm', 'gsm'];
export const REWARDS_BRANDS = ['nfsrm', 'atp', 'acp'];
export const BRANDED_REWARDS_BRANDS = ['nfsrm', 'atp', 'acp'];

export const DEFAULT_CACHING_LIMIT_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
export const CARD_IDS = {
  gm: {
    string: 'GUARANTEED',
    externalBrandCode: 'GUARANTEEDMASTERCARD',
  },
  gm22pct: {
    string: 'GUARANTEED',
    externalBrandCode: 'GUARANTEEDMASTERCARD22PCT',
  },
  gm26pct: {
    string: 'GUARANTEED',
    externalBrandCode: 'GUARANTEEDMASTERCARD26PCT',
  },
  gm30pct: {
    string: 'GUARANTEED',
    externalBrandCode: 'GUARANTEEDMASTERCARD30PCT',
  },
  gmdepo: {
    string: 'GUARANTEED',
    externalBrandCode: 'GUARANTEEDMASTERCARDDEPOSIT',
  },
  lgm: {
    string: 'LOWRATEGUARANTEED',
    externalBrandCode: 'GUARANTEEDLOWRATEMASTERCARD',
  },
  gsm: {
    string: 'GUARANTEEDSECURE',
    externalBrandCode: 'GUARANTEEDSECUREDMASTERCARD',
  },
  gsm22pct: {
    string: 'GUARANTEEDSECURE',
    externalBrandCode: 'GUARANTEEDSECUREDMASTERCARD22PCT',
  },
  gsm26pct: {
    string: 'GUARANTEEDSECURE',
    externalBrandCode: 'GUARANTEEDSECUREDMASTERCARD26PCT',
  },
  gsm30pct: {
    string: 'GUARANTEEDSECURE',
    externalBrandCode: 'GUARANTEEDSECUREDMASTERCARD30PCT',
  },
  atp: {
    string: 'ASPIREPLATINUM',
    externalBrandCode: 'ASPIRETRAVEL',
  },
  acp: {
    string: 'ASPIRECASHPLATINUM',
    externalBrandCode: 'ASPIRECASH',
  },
  costco: {
    string: 'COSTCO',
    externalBrandCode: 'COSTCOCANADA',
  },
  nfb: {
    string: 'NOFEEGUARANTEED',
    externalBrandCode: 'NOFEEGUARANTEEDMASTERCARD',
  },
  mmf: {
    string: 'MONTHLYMEMBERSHIPFEEGURANTEED',
    externalBrandCode: 'MMFGUARANTEEDMASTERCARD',
  },
  ghrcg: {
    string: 'GUARANTEEDCONTROLGROUP',
    externalBrandCode: 'GHRCONTROLGROUP',
  },
  nfsrm: {
    string: 'NOFEESMARTREWARDSMASTERCARD',
    externalBrandCode: 'NOFEESMARTREWARDSMASTERCARD',
  },
  afs: {
    string: 'AMFSMARTREWARDSMASTERCARD',
    externalBrandCode: 'AMFSMARTREWARDSMASTERCARD',
  },
};

export const ALL_SNIPPETS = {
  purchase: 'PURCHASE_AIR_SNIPPET',
  rewards: null,
  'balance-transfer': 'BALANCE_TRANSFER_AIR_SNIPPET',
  'cash-advance': 'CASH_ADVANCE_AIR_SNIPPET',
  'annual-fee': 'MEMBERSHIP_FEE_SNIPPET',
  'credit-limit': 'CREDIT_LIMIT_RANGE_SNIPPET',
  'product-highlight': 'REWARDS_SNIPPET',
  'card-highlight': 'CARD_HIGHLIGHT',
  'brand-card-highlight': 'CARD_NAME_SNIPPET',
};

export const LANGUAGES = {
  ENGLISH_CANADA: 'en-CA',
  FRENCH_CANADA: 'fr-CA',
  //because we consume English snippets from iCatalyst using language en-US
  ENGLISH_US: 'en-US',
  //because we consume French snippets from iCatalyst using language es-US
  SPANISH_US: 'es-US',
  //because en-US doubles up to give us English snippets from iCatalyst
  ENGLISH_ALL: ['en-CA', 'en-US', 'en'],
  //because es-US doubles up to give us French snippets from iCatalyst
  FRENCH_ALL: ['fr-CA', 'es-US', 'fr', 'es'],
  ENGLISH: 'en',
  FRENCH: 'fr',
};
