import { getCorrelationId } from './correlationId';

const sendSnowPlowData = (callback) => {
  if (typeof global.window === 'undefined' || typeof global.window.snowplow !== 'function') return;
  // Fix for page data timing issues: https://github.com/gatsbyjs/gatsby/commit/42f509eadb06753f7b529f3682f22e012c21dc9b
  if ('requestAnimationFrame' in global.window) {
    global.window.requestAnimationFrame(() => global.window.requestAnimationFrame(callback));
  } else {
    const simulateTimeOf2requestAnimationFrames = 32;
    setTimeout(callback, simulateTimeOf2requestAnimationFrames);
  }
};

export const sendSnowplowPageView = () => {
  const sendPageView = () => {
    global.window.snowplow('trackPageView', null, [
      {
        schema: 'iglu:com.capitalone.snowplow/application/jsonschema/1-0-0',
        data: {
          applicationId: getCorrelationId(),
        },
      },
    ]);
  };
  sendSnowPlowData(sendPageView);
};

export const sendSnowplowScrollTracking = (scrollPercentage) => {
  const sendScrollTracking = () => {
    global.window.snowplow(
      'trackSelfDescribingEvent',
      {
        schema: 'iglu:com.capitalone.snowplow/ui_interaction/jsonschema/1-0-0',
        data: {
          action: 'scroll',
          elementType: 'window',
          elementName: 'N/A',
          value: scrollPercentage,
        },
      },
      [
        {
          schema: 'iglu:com.capitalone.snowplow/application/jsonschema/1-0-0',
          data: {
            applicationId: getCorrelationId(),
          },
        },
      ],
    );
  };
  sendSnowPlowData(sendScrollTracking);
};
