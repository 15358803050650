const getTfsData = (pathName) => {
  const pathParts = pathName.split('/');
  const tfsLanguage = pathParts[pathParts.length - 2] === 'fr' ? 'fr-CN' : 'en-CN';
  let purePathNameParts = pathName.replace(/fr\/?$/, '') || '';
  purePathNameParts = purePathNameParts.indexOf('/') === 0 ? purePathNameParts : `/${purePathNameParts}`;
  purePathNameParts = purePathNameParts.split('/');
  const tfsFunction = purePathNameParts[1];
  const tfsUnit = purePathNameParts.length > 3 ? purePathNameParts[purePathNameParts.length - 2] : null;
  return { tfsLanguage, tfsFunction, tfsUnit };
};

const send247ChatNowData = (pathName) => {
  const { tfsLanguage, tfsFunction, tfsUnit } = getTfsData(pathName);
  const sessionScopeData = {
    tfsCountry: 'CAN',
    tfsLOB: 'Nonauth_Enterprise',
    tfsSection: 'Sales',
    tfsFunction: tfsFunction || 'home',
    tfsUnit: tfsUnit || '',
    tfsLanguage: tfsLanguage,
  };
  try {
    global.window.top._tfsq.pop();
    global.window.top._tfsq.push(['session', sessionScopeData, 'pageload', () => {}]);
  } catch (err) {
    console.warn('Sending data to 247 unsuccessful');
  }
};

const initiateChatNow247 = (pathName) => {
  if (typeof global.window === 'undefined') return;
  if (typeof global.window.top._tfsq !== 'undefined') {
    send247ChatNowData(pathName);
  } else {
    const isChat247ScriptLoaded = () => typeof global.window.top._tfsq !== 'undefined';
    const msTimeToWaitBetweenChecks = 200;
    let retries = 10;
    const retry247ChatNow = setInterval(() => {
      if (retries === 0) clearInterval(retry247ChatNow);
      if (isChat247ScriptLoaded()) {
        send247ChatNowData(pathName);
        clearInterval(retry247ChatNow);
      } else {
        retries -= 1;
      }
    }, msTimeToWaitBetweenChecks);
  }
};

export default initiateChatNow247;
