const mapdata = require('../../api-data/api-data.json');

// This function will replace fetch in spray-cans useCardDetails hook. It will have the fetch retrieve
// the static json files in teapot buckets instead of calling the api live.
// const cachedFetch = async (url) => {
const cachedFetch = async (url, ...args) => {
  const isDev = window?.location?.hostname?.includes('-dev');
  if (isDev) {
    return fetch(url, ...args);
  }
  const pathname = url.replace(/^[a-zA-Z]{3,5}:\/{2}[a-zA-Z0-9_.:-]+/, '').replace(/^\/err418/g, '');
  if (mapdata[pathname]) {
    return Promise.resolve({
      json: () => Promise.resolve(mapdata[pathname]),
      ok: true,
      status: 200,
    });
  }
  throw Error(`missing api data for url ${url}`);
};

module.exports = cachedFetch;
