export const LOCALSTORAGE_LANG_KEY = 'gatsby-intl-language';

// not found paths only for the default language
export const NOT_FOUND_DEFAULT_LANGUAGE = [
  '/404.html',
  '/404/',
];

// internal cache for NOT_FOUND_PATHS
const NOT_FOUND_PATHS_MEMO = {};

/**
 * return a list of paths for page not found on every possible language combination
 */
export const NOT_FOUND_PATHS = (languages, defaultLanguage) => {
  const key = `${languages.join(':')}-${defaultLanguage}`;

  NOT_FOUND_PATHS_MEMO[key] = NOT_FOUND_PATHS_MEMO[key] || NOT_FOUND_DEFAULT_LANGUAGE.concat(
    [].concat(...(NOT_FOUND_DEFAULT_LANGUAGE.map(path => (
      languages.map(lang => (lang !== defaultLanguage ? `/${lang}${path}` : '')).filter(e => !!e)
    )))),
  );

  return NOT_FOUND_PATHS_MEMO[key];
};
