import { sendSnowplowScrollTracking } from './snowplow';

const scrollTracker = (scrollTrackerState) => () => {
  if (!scrollTrackerState.currentInterval) {
    scrollTrackerState.currentInterval = setInterval(() => {
      sendSnowplowScrollTracking(
        Math.round((window.pageYOffset / (document.body.scrollHeight - window.innerHeight)) * 100).toString(),
      );
    }, 1000);
  }
  clearTimeout(scrollTrackerState.wait);
  scrollTrackerState.wait = setTimeout(() => {
    clearInterval(scrollTrackerState.currentInterval);
    scrollTrackerState.currentInterval = null;
  }, 1000);
};

const initiateScrollTracker = () => {
  const scrollTrackerState = { wait: null, currentInterval: null };
  const scrollTrackerFn = scrollTracker(scrollTrackerState);
  window.addEventListener('scroll', scrollTrackerFn);
};

export { initiateScrollTracker, scrollTracker };
