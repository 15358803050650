import { MIMEO_MOCK_BASE_URL, DEVEX_QA_BASE_URL, PROD_API_URL } from './constants';
import { sendErrorToNewRelic } from './newrelicSpa';

export const connectUrlToNewQS = (url) => {
  return `${url}${url.indexOf('?') >= 0 ? '&' : '?'}`;
};

/**
 * Injects qkref querystring based on the brand
 * @param {string} url Required
 * @param {ALL_BRAND} brand Required
 * @returns {string} url
 */
export const addCheckFirstQS = (url, brand) => {
  const parsedUrl = connectUrlToNewQS(url);
  if (['atp', 'acp'].includes(brand)) {
    return `${parsedUrl}qkref=${global.window.btoa('um')}`;
  } else if (brand === 'gsm') {
    return `${parsedUrl}qkref=${global.window.btoa('sghr')}`;
  }

  return `${parsedUrl}qkref=${global.window.btoa(brand)}`;
};

/**
 * ONLY /quickcheck page can have qkref value in the querystring within WWW.
 * We have this logic in `gatsby-plugin-conecad` as well.
 * @param {window.location} location Required
 * @returns {string} url
 */
export const removeCheckFirstQSIfNeeded = (location) => {
  try {
    let url = location.href;
    if (location.pathname.indexOf('/quickcheck') > -1) {
      return url;
    }

    let searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('qkref')) {
      return url;
    }

    searchParams.delete('qkref');
    url = `${location.origin}${location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, null, url);
    return url;
  } catch (error) {
    sendErrorToNewRelic(error, {
      errorSource: 'WWW Check First flow',
    });
    return location.href;
  }
};

/**
 * Checks if the current url contains an external_id as a query string
 * and will append it to the url passed to the function.
 * @param {string} url Required
 * @returns {string} url
 */
export const appendExternalID = (url) => {
  if (typeof window === 'undefined') return url;
  const qsParams = new URLSearchParams(window.location.search);

  if (qsParams.has('external_id')) {
    const isUrlWithQSParams = url.indexOf('?') >= 0;
    return `${url}${isUrlWithQSParams ? '&' : '?'}external_id=${qsParams.get('external_id')}`;
  }
  return url;
};

/**
 * @param {object} params
 * @param {string} params.url
 * @param {string} params.key
 * @param {string} params.value
 * @returns {string}
 */
export const addQSIfNeeded = ({ url, key, value }) => {
  const querystring = `${key}=${value}`;
  if (url.indexOf(querystring) > -1) {
    return url;
  }
  return `${connectUrlToNewQS(url)}${querystring}`;
};

export const getBaseApiUrl = () => {
  if (window) {
    if (window.location.hostname.includes('localhost')) {
      return MIMEO_MOCK_BASE_URL;
    } else if (window.location.hostname.includes('-dev')) {
      return DEVEX_QA_BASE_URL;
    }
  }
  return PROD_API_URL;
};
