/* eslint-disable */
import React from 'react';
import browserLang from 'browser-lang';

import { addLocaleData } from 'react-intl';
import { SprayCanProvider } from '@cof/graffiti-alley-spray-cans/context/app-context';

import { navigate } from './Link';
import { LOCALSTORAGE_LANG_KEY } from './constants';

/**
 * returns the react-intl data for a specific language
 */
const getLocaleData = language => {
  try {
    // eslint-disable-next-line import/no-dynamic-require
    const localeData = require(`react-intl/locale-data/${language}`); // eslint-disable-line global-require

    return localeData;
  } catch (e) {
    throw new Error(`Cannot find react-intl/locale-data/${language}\n\n${e.message}`);
  }
};

/**
 * inject the current language locale data into react-intl
 */
const addSiteLocaleData = locale => {
  const language = locale.split('-')[0];
  const localeData = getLocaleData(language);

  if (!localeData) {
    throw new Error(`No data found for: ${language}`);
  }

  addLocaleData(...localeData);
};

/**
 * wraps the page content into `spray-cans` provider
 */
const withProvider = intl => children => {
  addSiteLocaleData(intl.language);
  return (
    <SprayCanProvider localeData={intl} fieldLevelTracker={true}>
      {children}
    </SprayCanProvider>
  );
};

/**
 * Detect the prefered language for a specific page
 * 1. URL language is always prefered
 * 2. Page Language (context) if #1 fails
 * 3. App Memory language if #1 and #2 fails
 * 4. Cached prefered language if #1, #2, and #3 fails
 * 4. Falling back to user's browser preference when all else fails
 */
const detectLanguage = (pageContext, pluginOptions) => {
  // language loaded in the context of the page
  const pageLanguage = (pageContext.intl && pageContext.intl.language) || undefined;
  // language based on the url being loaded
  let urlLanguage;
  // language based on the app memory
  let memoryLanguage;
  // cached selected language
  let cachedLanguage;
  if (typeof window !== 'undefined') {
    urlLanguage = pluginOptions.languages.find(lang => window.location.pathname.endsWith(`/${lang}/`));
    memoryLanguage = window.___gatsbyIntl && window.___gatsbyIntl.language || undefined; // eslint-disable-line no-underscore-dangle
    try {
      cachedLanguage = window.localStorage && window.localStorage.getItem(LOCALSTORAGE_LANG_KEY);
    } catch {
      console.error('Local Storage is Disabled.')
      cachedLanguage = null;
    }
  }

  return urlLanguage || pageLanguage || memoryLanguage || cachedLanguage || browserLang({
    languages: pluginOptions.languages,
    fallback: pluginOptions.language,
  });
};

/**
 * export default `wrapperPageElement` that deals with `in18n` and `spray-cans` integration
 */
export default ({ element, props, loadPage, loadPageSync }, pluginOptions) => {
  if (!props) {
    return;
  }

  const { pageContext, location } = props;
  let { intl } = pageContext || {};
  const detected = detectLanguage(pageContext, pluginOptions);

  // INFO: Gatsby renders a hard-coded `/404.html` (treated as 404 default language by this plugin)
  //       the following code block handles non-default 404 language rendering
  if (
    detected !== intl.language
    && intl.originalPath
    && intl.originalPath !== location.pathname
    && intl.originalPath === '/404.html'
  ) {
    const notFoundLink = `/${detected}/404.html`;
    const pageData = loadPageSync(notFoundLink);
    if (pageData) {
      intl = pageData.json.pageContext.intl; // eslint-disable-line prefer-destructuring
    }
    else {
      // possible async solution
      loadPage(`/${detected}/404.html`)
        .then(pageData => { // eslint-disable-line no-shadow
          intl = pageData.json.pageContext.intl; // eslint-disable-line prefer-destructuring
          if (typeof window !== 'undefined') {
            try {
              localStorage.setItem(LOCALSTORAGE_LANG_KEY, intl.language);
            } catch {
              console.error('Local Storage is Disabled.')
            }
            window.___gatsbyIntl = intl; // eslint-disable-line no-underscore-dangle
          }

          // INFO: Since this is an async call the page was already rendered with an empty content.
          //       With the correct intl in memory we now navigate replacing the current history entry
          navigate(location.pathname, {
            routed: false,
            replace: true,
          });
        });

      // INFO: this will render an empty page waiting for the async page load to complete.
      return withProvider(intl)(null); // eslint-disable-line consistent-return
    }
  }

  // always keep the current internationalization data in memory for fast access
  if (typeof window !== 'undefined') {
    window.___gatsbyIntl = intl; // eslint-disable-line no-underscore-dangle
  }

  return withProvider(intl)(element); // eslint-disable-line consistent-return
};
