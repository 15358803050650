import cuid from 'cuid';

let sessionCorrelationId;

export const getCorrelationId = () => {
  if (!sessionCorrelationId) sessionCorrelationId = cuid();
  return sessionCorrelationId;
};

export const appendCorrelationIdQS = (url) => {
  const isUrlWithQSParams = url.indexOf('?') >= 0;
  return `${url}${isUrlWithQSParams ? '&' : '?'}corid=${getCorrelationId()}`;
};
