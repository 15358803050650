export default function newrelicPageAction(key, value) {
  if (!global.window?.newrelic) return;
  if (!!key && !!value) {
    try {
      let data = Object.assign({}, value);
      window.newrelic.addPageAction(key, data);
    } catch (e) {
      console.log('Unable to send custom page action to New Relic:' + e);
    }
  }
}

/**
 *
 * @param {Error} error
 * @param {Object} options
 * @param {string} options.errorSource
 * @param {string} options.errorMsg
 * @returns
 */
export const sendErrorToNewRelic = (error, options = {}) => {
  if (!global.window?.newrelic) return;
  try {
    window.newrelic.noticeError(error, options);
  } catch (error) {
    console.log('Unable to send error to New Relic:' + error);
  }
};
