/* eslint react/prop-types: 0 */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { sendSnowplowPageView } from './src/lib/snowplow';
import initiateChatNow247 from './src/lib/initiateChatNow247';
import newrelicPageAction from './src/lib/newrelicSpa';
import { initiateScrollTracker } from './src/lib/scrollTracker';
import { NaturalSelectionInitialize } from '@cof/natural-selection';
import { removeCheckFirstQSIfNeeded } from './src/lib/urls';
import { LinkContext } from '@cof/lighthouse-component-library';
import GatsbyLinkLighthouse from './plugins/gatsby-plugin-conecad/GatsbyLinkLighthouse';

const onRouteUpdate = ({ location }) => {
  const pageUrl = removeCheckFirstQSIfNeeded(location)
  sendSnowplowPageView();
  initiateChatNow247(location.pathname);
  newrelicPageAction('manualPageView', { pageUrl });
};

// Fix for scrolling issue to internal anchors in gatsby/reach-router.
// Relevant issues: https://github.com/gatsbyjs/gatsby/issues/25778
// https://github.com/reach/router/issues/228
const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash === '' && location.href.slice(-1) === '#') return false;
};

const onClientEntry = () => {
  initiateScrollTracker();
};

const wrapRootElement = ({ element }) => (
  <>
    <LinkContext.Provider
      value={{
        LinkComponent: ({ 
          /* eslint-disable react/prop-types */
          children,
          href,
          className,
          target,
          rel,
          referrerPolicy,
          ...props 
          /* eslint-enable react/prop-types */
        }) => (
          <GatsbyLinkLighthouse
            to={href}
            className={className}
            target={target}
            rel={rel}
            referrerPolicy={referrerPolicy}
            {...props}
          >
            {children}
          </GatsbyLinkLighthouse>
        ),
      }}
    >
      <NaturalSelectionInitialize />
      {element}
    </LinkContext.Provider>
  </>
);

export { onRouteUpdate, shouldUpdateScroll, onClientEntry, wrapRootElement };
