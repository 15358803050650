/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router'
import { Link as GatsbyLink } from 'gatsby';
import { injectIntl } from 'react-intl';

import { LOCALSTORAGE_LANG_KEY } from './constants';
const is = {
  relativeURL: url => /^\/(?!\/)/.test(url),
  capitalOneURL: url =>
    /^(https?:\/\/)?((www|www-qa-ca|www-dev-ca|fr|applynow|findyourcard|quickcheck-ui-canada-qa.aws-cof-canada-card-qa|applynow-qa).)?(capitalone.ca|.clouddqt.capitalone.com|capitalonegslbex.com|clouddqtext.capitalone.com)/.test(url),
  comparePageUrl: url => /\/credit-cards\/compare/.test(url),
  filterParam: param => param === 'filter',
};

const deriveQueryString = (location, parser) => {
  let qsParams = new URLSearchParams(location.search);
  if (location.pathname.indexOf("/quickcheck") < 0 && qsParams.has("qkref")) {
    // Only /quickcheck path can have qkref within WWW
    qsParams.delete("qkref")
  }
  qsParams.forEach((value, key) => {
    // Note: If same param exist in qs and href, the href's value is chosen to preserve URL
    if (!parser.searchParams.has(key)) parser.searchParams.append(key, value);
    // Note: On the compare card page, href's filter param value is updated when filter is changed when switching tabs
    else if (is.filterParam(key) && is.comparePageUrl(location.pathname)) parser.searchParams.set(key, value);
  });
  qsParams = parser.searchParams.toString();
  qsParams = qsParams ? `?${qsParams}` : '';
  return qsParams;
};

const DUMMY_BASE_FOR_URL_PARSER = 'https://localhost';

const setLanguageInLocalStorage = (lang) => {
  try {
    localStorage.setItem(LOCALSTORAGE_LANG_KEY, lang);
  } catch {
    console.error('Local Storage is Disabled.')
  }
}

/**
 * This component is a wrapper around the Gatsby <Link> component adding some extra functionality.
 * (check properties docs for more info)
 */
const GatsbyLinkLighthouse = injectIntl(({ to, language, children, onClick, intl, ...props }) => {
  const localeData = {
    defaultLanguage: 'en',
    language: intl.locale,
    languages: ['en', 'fr']
  }
  const location = useLocation();
  // The combination of isClient state and useEffect hook is necessary to fix initial render and rehydration issues.
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);

  let finalURL = to;
  const urlParser = new URL(to, DUMMY_BASE_FOR_URL_PARSER);
  const currentQueryString = isClient ? deriveQueryString(location, urlParser) : '';
  const languageQueryPath = language || localeData.language;
  const isLanguageLink = (language || localeData.language) && languageQueryPath !== localeData?.defaultLanguage;

  if (is.relativeURL(to)) {
    let finalPath = urlParser.pathname;
    if (isLanguageLink) finalPath = `${finalPath}${finalPath.slice(-1) !== '/' ? '/' : ''}${languageQueryPath}/`;
    finalURL = `${finalPath}${currentQueryString}${urlParser.hash}`;
  } else if (is.capitalOneURL(to)) {
    finalURL = `${urlParser.origin}${urlParser.pathname}${currentQueryString}${urlParser.hash}`;
  }

  const handleClick = e => {
    if (language) setLanguageInLocalStorage(language)
    if (onClick) onClick(e);
  };

  const isBypassBlock = props.className && props.className.includes('bypass-block');
  if (props.target === '_blank' || isBypassBlock) {
    return (
      <a href={finalURL} onClick={handleClick} {...props}>
        {children}
      </a>
    )
  }
  return (
    <GatsbyLink to={finalURL} onClick={handleClick} {...props}>
      {children}
    </GatsbyLink>
  );
});

GatsbyLinkLighthouse.propTypes = {
  /** link content */
  children: PropTypes.node.isRequired,
  /** url destination of the link */
  to: PropTypes.string.isRequired,
  /** use this option to set a specific language to this link */
  language: PropTypes.string,
  /** allows custom functionality to be implemented as the user clicks the link */
  onClick: PropTypes.func,
};

GatsbyLinkLighthouse.defaultProps = {
  to: null,
};

export default GatsbyLinkLighthouse;
